export default {
  // baseUrl: 'http://localhost:8093',
  // uploadBaseURL: 'http://localhost:8093/api',
  // baseUrl: 'https://btsotest.io.technik.fhnw.ch',
  // uploadBaseURL: 'https://btsotest.io.technik.fhnw.ch/api',
  // baseUrl: 'https://testing.btso.ch',
  // uploadBaseURL: 'https://testing.btso.ch/api',
  baseUrl: 'https://app.btso.ch',
  uploadBaseURL: 'https://app.btso.ch/api',

  // baseUrl: 'http://localhost/btso/back/public',
  // uploadBaseURL: 'http://localhost/btso/back/public/api',
  maxConcurrentUploads: 1
}